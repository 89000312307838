import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '@fontsource/unifont';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Drawer,
  Box,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

import badgeLogo from '../images/badge2.png';

import './Navbar.scss';

export default function Navbar() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const toggleDrawer = (open: any) => (event: any) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const toggleLanguage = () => {
    const currentLanguage = i18n.language;
    const newLanguage = currentLanguage === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLanguage);
  };

  const handleOpenNewTab = (url: string) => () => {
    window.open(url, '_blank');
  };

  return (
    <AppBar position="static" sx={{ backgroundColor: 'black' }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="menu"
          onClick={toggleDrawer(true)}
          sx={{ mr: 2, display: { sm: 'none' } }}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          className="badgeLogoContainer"
          variant="h6"
          component="div"
          sx={{ flexGrow: 1 }}
        >
          <img className="badgeLogo" src={badgeLogo}></img>
        </Typography>
        <Box
          className="menuItems"
          sx={{ display: { xs: 'none', sm: 'block' } }}
        >
          <Button color="inherit" component={Link} to="/">
            {t('navbar.home')}
          </Button>
          <Button color="inherit" component={Link} to="/about">
            {t('navbar.about')}
          </Button>
          <Button color="inherit" component={Link} to="/address">
            {t('navbar.location')}
          </Button>
          <Button
            component="a"
            color="inherit"
            onClick={handleOpenNewTab('https://game.letsctf.com/')}
          >
            CTF |
          </Button>
          <Button
            component="a"
            onClick={handleOpenNewTab('https://www.papercall.io/c1b3rtr4cks')}
            color="inherit"
          >
            CFP |
          </Button>
          <Button color="inherit" component={Link} to="/program">
            {t('navbar.program')}
          </Button>
          <Button color="inherit" component={Link} to="/previous">
            {t('navbar.previous')}
          </Button>
          <Button
            sx={{
              border: '1px solid rgb(0, 217, 255)',
              color: 'rgb(0, 217, 255)',
            }}
            className="languageButton"
            color="inherit"
            onClick={toggleLanguage}
          >
            {t('navbar.language')}
          </Button>
        </Box>

        <Drawer
          anchor="left"
          open={isDrawerOpen}
          onClose={toggleDrawer(false)}
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: 'black',
              color: 'white',
              borderRight: '2px solid gray',
              padding: '16px',
            },
          }}
        >
          <Box
            sx={{ width: 250 }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li className="link-wrapper">
                <Link
                  to="/"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '8px',
                  }}
                >
                  {t('navbar.home')}
                </Link>
              </li>
              <li className="link-wrapper">
                <Link
                  to="/about"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '8px',
                  }}
                >
                  {t('navbar.about')}
                </Link>
              </li>
              <li className="link-wrapper">
                <Link
                  to="/address"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '8px',
                  }}
                >
                  {t('navbar.location')}
                </Link>
              </li>
              <li className="link-wrapper">
                <a
                  href="https://game.letsctf.com/"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '8px',
                  }}
                >
                  CTF |
                </a>
              </li>
              <li className="link-wrapper">
                <a
                  href="https://www.papercall.io/c1b3rtr4cks"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '8px',
                  }}
                >
                  CFP |
                </a>
              </li>
              <li className="link-wrapper">
                <Link
                  to="/program"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '8px',
                  }}
                >
                  {t('navbar.program')}
                </Link>
              </li>
              <li className="link-wrapper">
                <Link
                  to="/previous"
                  style={{
                    textDecoration: 'none',
                    color: 'inherit',
                    marginBottom: '8px',
                  }}
                >
                  {t('navbar.previous')}
                </Link>
              </li>
              <li className="link-wrapper">
                <Button
                  sx={{ border: '1px solid red' }}
                  color="inherit"
                  onClick={toggleLanguage}
                >
                  {t('navbar.language')}
                </Button>
              </li>
            </ul>
          </Box>
        </Drawer>
      </Toolbar>
    </AppBar>
  );
}
