import { useTranslation } from 'react-i18next';
import { Container, Typography, List, ListItem, Box } from '@mui/material';

import Navbar from '../../navbar/Navbar';

export default function PrivacyPolicy() {
  const { t } = useTranslation();

  return (
    <Box sx={{ background: 'black', color: 'white' }}>
      <Navbar />
      <Container maxWidth="md">
        <Box
          sx={{
            marginTop: 4,
            marginBottom: 2,
          }}
        >
          <Typography
            variant="h3"
            component="h1"
            gutterBottom
            textAlign="center"
          >
            {t('privacyPolicy.intro.title')}
          </Typography>
          <Typography paragraph>{t('privacyPolicy.intro.text')}</Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.whoIsResponsible.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.whoIsResponsible.identity')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.whoIsResponsible.adress')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.whoIsResponsible.mobile')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.whoIsResponsible.mail')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.whoIsResponsible.text')}
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.dataProcessing.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataProcessing.text')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.adressRequired.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.adressRequired.text')}
          </Typography>
          <Typography paragraph>{t('privacyPolicy.purpose.text1')}</Typography>
          <Typography paragraph>{t('privacyPolicy.purpose.text2')}</Typography>
          <Typography paragraph>{t('privacyPolicy.purpose.text3')}</Typography>
          <Typography paragraph>{t('privacyPolicy.purpose.text4')}</Typography>
          <Typography paragraph>{t('privacyPolicy.purpose.text5')}</Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.dataRetention.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataRetention.text1')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataRetention.text2')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataRetention.text3')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataRetention.text4')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataRetention.text5')}
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.legalBasis.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.legalBasis.text1')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.legalBasis.text2')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.legalBasis.text3')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.legalBasis.text4')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.legalBasis.text5')}
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.dataSharing.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataSharing.text1')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataSharing.text2')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataSharing.text3')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataSharing.text4')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.dataSharing.text5')}
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.rights.title')}
          </Typography>
          <List>
            <ListItem>{t('privacyPolicy.rights.list1')}</ListItem>
            <ListItem>{t('privacyPolicy.rights.list2')}</ListItem>
            <ListItem>{t('privacyPolicy.rights.list3')}</ListItem>
            <ListItem>{t('privacyPolicy.rights.list4')}</ListItem>
            <ListItem>{t('privacyPolicy.rights.list5')}</ListItem>
            <ListItem>{t('privacyPolicy.rights.list6')}</ListItem>
            <ListItem>{t('privacyPolicy.rights.list7')}</ListItem>
          </List>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.under14.title')}
          </Typography>
          <Typography paragraph>{t('privacyPolicy.under14.text1')}</Typography>
          <Typography paragraph>{t('privacyPolicy.under14.text2')}</Typography>
          <Typography paragraph>{t('privacyPolicy.under14.text3')}</Typography>
          <Typography paragraph>{t('privacyPolicy.under14.text4')}</Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.thirdPartyLinks.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.thirdPartyLinks.text')}
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.socialMediaPolicy.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.socialMediaPolicy.text1')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.socialMediaPolicy.text2')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.socialMediaPolicy.text3')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.socialMediaPolicy.text4')}
          </Typography>

          <Typography variant="h5" component="h2" gutterBottom>
            {t('privacyPolicy.policyChanges.title')}
          </Typography>
          <Typography paragraph>
            {t('privacyPolicy.policyChanges.text')}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
