import { useState } from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';

import tableInfo from '../program/table/prevTableInfo.json';
import { TableProgram } from '../program/table/tableProgram';
import './previous.scss';
import Footer from '../footer/footer';

export default function Previous() {
  const { t } = useTranslation();
  const [selectedYear, setSelectedYear] = useState('');

  // Función para manejar el clic en los botones de año
  const handleYearClick = (year: string) => {
    setSelectedYear(year);
  };

  return (
    <Box className="previousContainer">
      <ButtonGroup variant="outlined" className="yearButtonGroup">
        <Button
          className={`yearButton ${selectedYear === '2022' ? 'active' : ''}`}
          onClick={() => handleYearClick('2022')}
        >
          2022
        </Button>
        <Button
          className={`yearButton ${selectedYear === '2023' ? 'active' : ''}`}
          onClick={() => handleYearClick('2023')}
        >
          2023
        </Button>
      </ButtonGroup>
      {selectedYear === '2023' && (
        <TableProgram tableInfo={tableInfo}></TableProgram>
      )}
      <Footer />
    </Box>
  );
}
