import React, { useEffect, useRef } from 'react';

const Matrix = () => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext('2d');
      if (ctx) {
        const resizeCanvas = () => {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
        };
        window.addEventListener('resize', resizeCanvas);
        resizeCanvas();

        ctx.fillStyle = '#000';
        ctx.fillRect(0, 0, canvas.width, canvas.height);

        const fontSize = 16;
        const texts = '10'.split('');
        const columnCount = Math.ceil(canvas.width / fontSize);
        const rowCount = Math.ceil(canvas.height / fontSize);

        const rowStatus: number[] = Array(columnCount)
          .fill(0)
          .map(() => Math.floor(Math.random() * rowCount) * -1);

        const draw = () => {
          ctx.fillStyle = 'rgba(0,0,0,0.05)';
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          rowStatus.forEach((row, column) => {
            const text = texts[Math.floor(Math.random() * texts.length)];
            ctx.fillStyle = 'rgb(0, 217, 255)';
            ctx.fillText(text, column * fontSize, row * fontSize);

            rowStatus[column] += 1;
            if (rowStatus[column] > rowCount) {
              rowStatus[column] = 0;
            }
          });
        };

        const interval = setInterval(draw, 100);

        return () => {
          clearInterval(interval);
          window.removeEventListener('resize', resizeCanvas);
        };
      }
    }
  }, []);

  return <canvas ref={canvasRef} />;
};

export default Matrix;
