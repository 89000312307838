import { useTranslation } from 'react-i18next';
import './tableProgram.scss'; // Asegúrate de que este import está presente
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Box, Typography } from '@mui/material';

import '@fontsource/unifont';

interface TableRowData {
  time: string;
  location: string;
  event: string;
  name: string;
}

export const TableProgram = ({ tableInfo }: { tableInfo: any }) => {
  const { t } = useTranslation();

  return (
    <TableContainer className="tableContainer" component={Paper}>
      <Table className="table" aria-label="customized table">
        <TableHead className="tableHead">
          <TableRow className="tableRowTitle">
            <TableCell className="tableRow">{t('table.hour')}</TableCell>
            <TableCell className="tableRow">{t('table.event')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className="tableBody">
          {tableInfo &&
            tableInfo.map((row: TableRowData, index: number) => (
              <TableRow className="tableRow" key={index}>
                <TableCell className="tableCell" component="th" scope="row">
                  {row.time || `${t('table.to_be_announced')}`}
                </TableCell>
                <TableCell className="tableCell" component="th" scope="row">
                  <Box className="event">
                    {row.event || `${t('table.to_be_announced')}`}
                    <Typography className="nameBlur">
                      {row.name || ''}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
