import { Box, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './footer.scss';
import fsLogo from '../images/fs.png';
import uamLogo from '../images/uam.png';

export default function Footer() {
  const { t } = useTranslation();

  return (
    <Box component="footer" className="footerContainer">
      <Box className="reserved">
        <img className="logo" src={uamLogo}></img>
        <img className="logo" src={fsLogo}></img>
      </Box>
      <Box className="legalAdvise">
        {' '}
        <Link
          className="legalAdviseButton"
          href="/privacy-policy"
          underline="hover"
        >
          {t('footer.privacyPolicy')}
        </Link>
        <Link
          className="legalAdviseButton"
          href="/legal-notice"
          underline="hover"
        >
          {t('footer.legalNoticy')}
        </Link>
      </Box>
    </Box>
  );
}
