import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Fab } from '@mui/material';
import XIcon from '@mui/icons-material/X';

import About from './about/about';
import Adress from './adress/Adress';
import Home from './home/home';
import Navbar from './navbar/Navbar';
import Program from './program/Program';
import Previous from './previous/Previous';

import './index.scss';
import '@fontsource/unifont';

export default function Index() {
  const location = useLocation();
  const homeRef = useRef<HTMLDivElement>(null);
  const aboutRef = useRef<HTMLDivElement>(null);
  const adressRef = useRef<HTMLDivElement>(null);
  const programRef = useRef<HTMLDivElement>(null);
  const previusRef = useRef<HTMLDivElement>(null);

  const handleFabClick = () => {
    window.open('https://twitter.com/c1b3rtr4cks', '_blank');
  };

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        homeRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case '/about':
        aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case '/address':
        adressRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case '/program':
        programRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      case '/previous':
        previusRef.current?.scrollIntoView({ behavior: 'smooth' });
        return;
      default:
        return;
    }
  }, [location]);

  return (
    <Box>
      <Navbar />
      <Box className="scroll-container">
        <Box className="home-container" ref={homeRef}>
          <Home />
        </Box>
        <Box ref={aboutRef}>
          <About />
        </Box>
        <Box ref={adressRef}>
          <Adress />
        </Box>
        <Box ref={programRef}>
          <Program />
        </Box>
        <Box ref={previusRef}>
          <Box sx={{ height: '100%' }}>
            <Previous />
          </Box>
        </Box>
      </Box>
      <Fab
        aria-label="X"
        style={{
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          background: 'black',
          color: 'white',
          border: '1px solid white',
        }}
        onClick={handleFabClick}
      >
        <XIcon />
      </Fab>
    </Box>
  );
}
