import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import tableInfo from '../program/table/tableInfo.json';

import { TableProgram } from './table/tableProgram';
import './program.scss';

export default function Prog() {
  const { t } = useTranslation();

  const [title, setTitle] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const titleRef = useRef(null);

  const [finalTitle, setFinalTitle] = useState('');

  useEffect(() => {
    setFinalTitle(
      `find /${t('program.events')} -name '${t('program.schedules')}*' -type f -exec cat {} \\;`
    );
  }, [t]);

  const generateRandomChar = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    return characters.charAt(Math.floor(Math.random() * characters.length));
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          } else {
            setIsVisible(false);
          }
        });
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isVisible) return;
    setTitle(
      Array.from(finalTitle)
        .map(() => generateRandomChar())
        .join('')
    );

    const interval = setInterval(() => {
      setTitle((currentTitle) =>
        currentTitle
          .split('')
          .map((char, index) => {
            return index < finalTitle.length ? generateRandomChar() : char;
          })
          .join('')
      );
    }, 100);

    const timeout = setTimeout(() => {
      clearInterval(interval);
      setTitle(finalTitle);
    }, 700);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [isVisible, finalTitle]);

  return (
    <Box className="programContainer" ref={titleRef}>
      <Box className="tittleContainer">
        <Typography className="tittle">{title}</Typography>
      </Box>
      <TableProgram tableInfo={tableInfo}></TableProgram>
    </Box>
  );
}
