import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Typography, Box } from '@mui/material';

import Navbar from '../../navbar/Navbar';

export default function LegalNotice() {
  const { t } = useTranslation();

  return (
    <Box sx={{ background: 'black', color: 'white' }}>
      <Navbar />
      <Container maxWidth="md">
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" gutterBottom>
            {t('legalNotice.legalNotice.title')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.legalNotice.text1')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.legalNotice.text2')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.legalNotice.text3')}
          </Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.object.title')}
          </Typography>
          <Typography paragraph>{t('legalNotice.object.text1')}</Typography>
          <Typography paragraph>{t('legalNotice.object.text2')}</Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.ownership.title')}
          </Typography>
          <Typography paragraph>{t('legalNotice.ownership.text1')}</Typography>
          <Typography paragraph>{t('legalNotice.ownership.text2')}</Typography>
          <Typography paragraph>{t('legalNotice.ownership.text3')}</Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.propertyRights.title')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.propertyRights.text1')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.propertyRights.text2')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.propertyRights.text3')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.propertyRights.text4')}
          </Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.contentsOutside.title')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.contentsOutside.text1')}
          </Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.responsibilities.title')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.responsibilities.text1')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.responsibilities.text2')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.responsibilities.text3')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.responsibilities.text4')}
          </Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.dataProtection.title')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.dataProtection.text1')}
          </Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.durationModification.title')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.durationModification.text1')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.durationModification.text2')}
          </Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.suspension.title')}
          </Typography>
          <Typography paragraph>{t('legalNotice.suspension.text1')}</Typography>
          <Typography paragraph>{t('legalNotice.suspension.text2')}</Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.nulityIneffectiveness.title')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.nulityIneffectiveness.text1')}
          </Typography>
          <Typography paragraph>
            {t('legalNotice.nulityIneffectiveness.text2')}
          </Typography>

          <Typography variant="h5" gutterBottom>
            {t('legalNotice.law.title')}
          </Typography>
          <Typography paragraph>{t('legalNotice.law.text1')}</Typography>
        </Box>
      </Container>
    </Box>
  );
}
