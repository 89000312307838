import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ctLogo from '../images/ct_logo.png';
import './home.scss';

import Matrix from './canvas/matrix';

export default function Home() {
  const { t } = useTranslation();

  const endDate = moment('07/06/2024', 'DD/MM/YYYY');
  const [timeLeft, setTimeLeft] = useState({
    totalDays: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const now = moment();
      const totalDays = endDate.diff(now, 'days');
      const duration = moment.duration(endDate.diff(now));

      setTimeLeft({
        totalDays,
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate]);

  const handleOpenNewTab = () => {
    window.open('https://www.inscribirme.com/3aedicionc1b3rtr4cks', '_blank');
  };

  return (
    <Box className="homeContainer">
      <Box className="canvasContainer">
        <Matrix />
      </Box>
      <Box className="logoContainer">
        <img src={ctLogo} alt="Logo" />
      </Box>
      <Typography variant="h4" className="mainTitle">
        {t('home.title')}
      </Typography>
      <Typography className="dateTitle">
        {endDate.format('DD/MM/YYYY')}
      </Typography>
      <Grid
        container
        className="timeContainer"
        justifyContent="center"
        spacing={2}
      >
        <Typography className="timeText">{`${timeLeft.totalDays} ${t('home.days')} ${timeLeft.hours} ${t('home.hours')} ${timeLeft.minutes} ${t('home.minutes')} ${timeLeft.seconds} ${t('home.seconds')}`}</Typography>
      </Grid>
      <Box>
        <Button
          className="signupButton"
          variant="contained"
          onClick={handleOpenNewTab}
        >
          {t('home.singUpButton')}
        </Button>
      </Box>
    </Box>
  );
}
