import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import './Adress.scss';
export default function Address() {
  const { t } = useTranslation();

  const mapURL =
    'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d189.48449979250856!2d-3.691552!3d40.547068!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422bafcfe8b54f%3A0x6efe6978bbddb878!2sEscuela%20Polit%C3%A9cnica%20Superior%20%7C%20UAM!5e0!3m2!1ses!2ses!4v1707561725996!5m2!1ses!2ses';

  return (
    <Box className="adressContainer">
      <Box className="tittleContainer">
        <Typography className="tittle">{t('address.title')}</Typography>
        <Typography className="description">
          {t('address.description')}
        </Typography>
      </Box>
      <Box className="mapContainer">
        <iframe
          className="map"
          src={mapURL}
          width="100%"
          height="450"
          style={{ border: 0 }}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </Box>
    </Box>
  );
}
