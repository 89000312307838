import { Fragment } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';

import LegalNotice from './index/footer/legal/legalNoticy';
import PrivacyPolicy from './index/footer/legal/privacyPolicy';

import Index from './index';

export default function App() {
  const { t } = useTranslation();
  return (
    <Fragment>
      <CssBaseline />
      <CookieConsent
        location="bottom"
        buttonText={t('cookies.accept')}
        declineButtonText={t('cookies.refuse')}
        cookieName="userConsentCookie"
        style={{ background: '#2B373B', padding: '0 80px' }}
        buttonStyle={{
          background: 'rgb(0, 217, 255)',
          color: '#4e503b',
          fontSize: '13px',
        }}
        declineButtonStyle={{ color: '#ffffff', fontSize: '13px' }}
        expires={150}
        enableDeclineButton
      >
        {t('cookies.text')}
      </CookieConsent>
      <Router>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<Index />} />
          <Route path="/address" element={<Index />} />
          <Route path="/program" element={<Index />} />
          <Route path="/previous" element={<Index />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </Router>
    </Fragment>
  );
}
